import {
  IMAGE_FORMAT,
  IMAGE_TYPE,
  IMAGE_RESOLUTION,
  IMAGE_QUALITY,
  IMAGE_QUALITY_MAP,
  IMAGE_RESOLUTIONS_MAP,
  IMAGE_SUPPORT_QUALITIES_MAP,
} from '~/constants/image'
import type { GetImageUrl } from '~/types'

/**
 * This composable provides a function to generate image URLs based on the given
 * path and options.
 */
export default function useImageUrl() {
  const runtimeConfig = useRuntimeConfig()

  /**
   * Generates an image URL based on the given path and options.
   */
  function getImageUrl({
    path,
    format = IMAGE_FORMAT.webp,
    resolution = IMAGE_RESOLUTION.small,
    quality = IMAGE_QUALITY.default,
  }: GetImageUrl): string | undefined {
    if (!path) {
      return
    }

    // Return the path as is if it's a campground asset URL.
    if (path.startsWith(runtimeConfig.public.apps.campgrounds.assetsURL)) {
      return path
    }

    // Remove the dynamic assets URL from the path if its an absolute URL.
    if (/^https?:\/\//.test(path)) {
      path = path.replace(runtimeConfig.public.dynamicAssetsURL, '')
    }

    // Convert input to lowercase to look up the right value.
    let sanitizedFormat = format.toLowerCase() as keyof typeof IMAGE_FORMAT

    // JPEG and JFIF are the same as JPG in our system.
    if (['jpeg', 'jfif'].includes(sanitizedFormat)) {
      sanitizedFormat = IMAGE_FORMAT.jpg
    }

    // Extract the image type from the path.
    const matchedPath = path.match(/^\/([^/]+)\//)?.[1]?.toLowerCase() as keyof typeof IMAGE_TYPE

    // Return early if the image type is not supported.
    if (!matchedPath) {
      return
    }

    const imageType = IMAGE_TYPE[matchedPath]

    // Return early if the image type, format, resolution, or quality is not supported.
    if (
      !imageType
      || !IMAGE_FORMAT[sanitizedFormat]
      || !IMAGE_RESOLUTIONS_MAP[imageType][resolution]
      || !IMAGE_SUPPORT_QUALITIES_MAP[format][quality]
    ) {
      return
    }

    const resolutionPath = IMAGE_RESOLUTIONS_MAP[imageType][resolution]
    const qualityPath = IMAGE_QUALITY_MAP[quality] ? `x${IMAGE_QUALITY_MAP[quality]}` : ''

    return `${
      /\/prod\//.test(path) ? runtimeConfig.public.prodDynamicAssetsURL : runtimeConfig.public.dynamicAssetsURL
    }/${resolutionPath}${qualityPath}${path.replace(/\.[a-z]+$/i, `.${format}`)}`
  }

  return {
    getImageUrl,
  }
}
